import React from "react";
import { Route, Switch } from "react-router-dom";
import Dashboard from "./components/Dashboard";
import Login from "./components/Login";
import NotFound from "./components/NotFound";
import AgentDashboard from "./components/AgentDashboard";
import PendingProposals from "./components/PendingProposals";
import OverDues from "./components/OverDues";
import ActiveEnrolments from "./components/ActiveEnrolments";
import DocumentsView from "./components/DocumentsView";
import PaymentRecordView from "./components/PaymentRecordView";
import CommunicationsView from "./components/CommunicationsView ";
import FutureCommission from "./components/FutureCommissionView";
import CommissionDueView from "./components/CommissionDueView";

export const DashboardRoutes = () => (
  <div className="lg:mx-8 mx-5 max-w-full">
    <Route path="/dashboard/PendingApplications" component={PendingProposals} />
    <Route path="/dashboard/OverDues" component={OverDues} />
    <Route path="/dashboard/communications" component={CommunicationsView} />
    <Route path="/dashboard/documents" component={DocumentsView} />
    <Route path="/dashboard/FutureCommission" component={FutureCommission} />
    <Route path="/dashboard/commissionDue" component={CommissionDueView} />
    <Route
      path="/dashboard/paidCommissionsView"
      component={PaymentRecordView}
    />
    <Route exact path="/dashboard" component={AgentDashboard} />
    <Route path="/dashboard/ActiveEnrolments" component={ActiveEnrolments} />
  </div>
);

export const Routes = () => (
  <React.Fragment>
    <Switch>
      <Route path="/login" component={Login} />
      <Route path="/dashboard" component={Dashboard} />
      <Route path="/" component={Login} />
      <Route path="*" component={NotFound} />
    </Switch>
  </React.Fragment>
);
