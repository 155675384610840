import React, { Component } from "react";
import Loader from "react-loader";
import TableComponent from "./common/TableComponent";
import moment from "moment";
import DateBox from "./controls/DateBox";
import Transition from "./common/Transition";
import SearchBox from "./controls/SearchBox";
import * as constants from "../constants";
import { DateFormatter } from "./common/DateFormatter";
import { currencyFormat, multiselectFilter } from "./common/commonFns";
import MultiSelectBox from "./controls/MultiSelectBox";
import { AgentsRequests } from "../apiRequests/AgentsRequests";

const dateFormatter = new DateFormatter();
const agentsRequests = new AgentsRequests();

class CommissionDueView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hidden: "hidden",
      isLoaded: true,
      agentId: localStorage.getItem("agentId"),
      tableRows: [],
      tableEmpty: "hidden",
      firstRowIndex: 0,
      lastRowIndex: constants.NUMBEROFROWS - 1,
      filterOn: false,
      dataLength: 0,
      searchFieldValue: "",
      isOpen: false,
      expanded: "",
      background: "hidden",
      fromDate: "",
      toDate: "",
      commissionsDetailsData: [],
      commissionsDetailsDataCopy: [],
      allPrograms: [],
      allCampuses: [],
      allInvoiceNo: [],
      campusSelectedList: [],
      invoiceNoSelectedList: [],
      programsSelectedList: [],
    };
    this.setPageIndexFn = this.setPageIndexFn.bind(this);
    this.handleDateBox = this.handleDateBox.bind(this);
  }

  componentWillMount() {
    this.setState({
      hidden: "",
      isLoaded: false,
    });
    let startDate = localStorage.getItem("t6StartDate");
    let endDate = localStorage.getItem("t6EndDate");
    this.setState(
      {
        fromDate: startDate,
        toDate: endDate,
      },
      function () {
        this.feesPaidEnrolmentsFn(startDate, endDate);
      }
    );
  }

  feesPaidEnrolmentsFn(startDate, endDate) {
    agentsRequests.feesPaidEnrolments(startDate, endDate).then((response) => {
      if (response != "error") {
        this.commissionDueFn(startDate, endDate, response);
      }
    });
  }

  commissionDueFn(startDate, endDate, feesPaidEnrolments) {
    agentsRequests.agentCommissionDue(startDate, endDate).then((result) => {
      if (result !== "error") {
        let filteredData = [];
        let combinedData = {};
        result.map((object) => {
          let a = feesPaidEnrolments.find(
            (element) => element.enrolmentID == object.enrolmentID
          );

          if (a) {
            combinedData.enrolmentID = a.enrolmentID;
            combinedData.commission = object.totalCommission;
            combinedData.agentInvoiceNo = object.agentInvoiceNo;
            combinedData.campus = object.campus;
            combinedData.enrolmentNo = object.enrolmentNo;
            combinedData.invoiceDate = object.invoiceDate;
            combinedData.program = object.program;
            combinedData.studentName = object.studentName;
            combinedData.studentInvoiceNo = a.invoiceNo;
            combinedData.studentPayment = a.paidAmount;

            filteredData.push({ ...combinedData });
          }
        });
        this.setState(
          {
            commissionsDetailsData: filteredData,
            commissionsDetailsDataCopy: filteredData,
            dataLength: filteredData.length,
          },
          function () {
            this.loadMultiSelectData();
          }
        );
      } else {
        this.setState({ tableEmpty: "" });
      }
    });
  }

  loadMultiSelectData() {
    let allPrograms = [];
    let allCampuses = [];
    let allInvoiceNo = [];

    let data = this.state.commissionsDetailsDataCopy;

    data.map((object) => {
      if (allPrograms.some((item) => object.program === item)) {
      } else {
        allPrograms.push(object.program);
      }
    });

    data.map((object) => {
      if (allCampuses.some((item) => object.campus === item)) {
      } else {
        allCampuses.push(object.campus);
      }
    });

    data.map((object) => {
      if (allInvoiceNo.some((item) => object.agentInvoiceNo === item)) {
      } else {
        allInvoiceNo.push(object.agentInvoiceNo);
      }
    });

    this.setState(
      {
        allPrograms: allPrograms,
        allCampuses: allCampuses,
        allInvoiceNo: allInvoiceNo,
      },
      function () {
        this.loadData();
      }
    );
  }

  loadData() {
    let tableRows = [];
    let formatedRowData = {};
    let data = this.state.commissionsDetailsDataCopy;
    let firstRowIndex = this.state.firstRowIndex;
    let lastRowIndex = this.state.lastRowIndex;

    data = this.dataProcessor(data);

    let dataLength = data.length;
    if (dataLength < parseInt(firstRowIndex) + 1) {
      firstRowIndex = 0;
      lastRowIndex = constants.NUMBEROFROWS - 1;
    }

    if (data != "" && data != null) {
      data.map((object, index) => {
        if (index >= firstRowIndex && index <= lastRowIndex) {
          formatedRowData.ImgSrc =
            "https://ui-avatars.com/api/name=" +
            object.studentName +
            "/?background=random";
          formatedRowData.Label1 = object.studentName;
          formatedRowData.DescriptionClm1 =
            " EnrolmentNo:" + object.enrolmentNo;
          formatedRowData.Label2 = object.program;
          formatedRowData["Campus"] = object.campus;
          formatedRowData["Student Invoice Number"] = object.studentInvoiceNo;
          formatedRowData["Student Payment"] =
            "$" + currencyFormat(object.studentPayment);
          formatedRowData["Agent Invoice Number"] = object.agentInvoiceNo;

          let invoiceDate = moment(object.invoiceDate, "DD/MM/YYYY").format(
            "DD-MM-YYYY"
          );
          formatedRowData["Invoice Date"] = invoiceDate;
          formatedRowData.Value = "$" + currencyFormat(object.commission);

          tableRows.push({ ...formatedRowData });
        }
      });
    } else {
      //following line will unhide Nodata View
      this.setState({ tableEmpty: "" });
    }

    this.setState({ tableRows: tableRows });

    this.setState({
      hidden: "hidden",
      isLoaded: true,
    });
  }

  renderTable() {
    let tableHeadings = [
      "Student Details",
      "Program",
      "Campus",
      "Student Invoice Number",
      "Student Payment",
      "Agent Invoice Number",
      "Invoice Date",
      "Commission",
      "",
    ];
    let tableRows = this.state.tableRows;

    if (tableRows.length >= 1) {
      return (
        <TableComponent
          tableHeadings={tableHeadings}
          tableRows={tableRows}
          filterOn={this.state.filterOn}
          numberOfElementsPerPage={constants.NUMBEROFROWS}
          dataLength={this.state.dataLength}
          setPageIndexFn={this.setPageIndexFn}
          firstRowIndex={this.state.firstRowIndex}
          lastRowIndex={this.state.lastRowIndex}
          clmTwoWidth={"w-1/4"}
        />
      );
    } else {
      return (
        <div
          className={
            this.state.tableEmpty +
            " text-gray-600 w-full flex justify-center mt-20"
          }
        >
          <h1>
            There is No Data Available to Display, please reset filters if any
            applied.
          </h1>
        </div>
      );
    }
  }

  setPageIndexFn(firstRowIndex, lastRowIndex) {
    this.setState(
      { firstRowIndex: firstRowIndex, lastRowIndex: lastRowIndex },
      function () {
        this.loadData();
      }
    );
  }

  searchHandeler() {
    this.setState({ value: searchValue });
    var searchValue = document.getElementById("search").value;
    let data = this.state.commissionsDetailsData;

    if (searchValue != "") {
      data = data.filter((object) =>
        object.studentName.toUpperCase().includes(searchValue.toUpperCase())
      );
    }

    if (data.length === 0) {
      this.setState({ tableEmpty: "" });
    } else {
      this.setState({ tableEmpty: "hidden" });
    }

    this.setState({ commissionsDetailsDataCopy: data }, function () {
      this.loadMultiSelectData();
    });
  }

  dataProcessor(data) {
    let programsSelectedList = this.state.programsSelectedList;
    let campusSelectedList = this.state.campusSelectedList;
    let invoiceNoSelectedList = this.state.invoiceNoSelectedList;

    if (programsSelectedList != "") {
      data = multiselectFilter(data, programsSelectedList, "program");
    }
    if (campusSelectedList != "") {
      data = multiselectFilter(data, campusSelectedList, "campus");
    }
    if (invoiceNoSelectedList != "") {
      data = multiselectFilter(data, invoiceNoSelectedList, "agentInvoiceNo");
    }

    // following line of code will set dataLength
    this.setState({ dataLength: data.length });
    return data;
  }

  filtersDropdown() {
    let expanded = this.state.expanded;
    if (expanded) {
      this.setState({
        isOpen: false,
        expanded: "",
        background: "hidden",
      });
    } else {
      this.setState({
        isOpen: true,
        expanded: "yes",
        background: "",
      });
    }
  }

  handleDateBox(e, name) {
    let selectedDate = e;

    let fromDate = new Date(dateFormatter.formatterThree(this.state.fromDate));
    let toDate = new Date(dateFormatter.formatterThree(this.state.toDate));
    let sDate = new Date(dateFormatter.formatterThree(selectedDate));
    localStorage.setItem("dateRangeLabel6", "Custom Range");
    if (name === "FromDate") {
      let startDate = dateFormatter.formatterTwo(sDate);
      let endDate = dateFormatter.formatterTwo(toDate);
      localStorage.setItem("t6StartDate", startDate);
      this.setState(
        {
          fromDate: selectedDate,
          isOpen: false,
          expanded: "",
          background: "hidden",
        },
        function () {
          this.feesPaidEnrolmentsFn(startDate, endDate);
        }
      );
    } else if (name === "ToDate") {
      let startDate = dateFormatter.formatterTwo(fromDate);
      let endDate = dateFormatter.formatterTwo(sDate);
      localStorage.setItem("t6EndDate", endDate);
      this.setState(
        {
          toDate: selectedDate,
          isOpen: false,
          expanded: "",
          background: "hidden",
        },
        function () {
          this.feesPaidEnrolmentsFn(startDate, endDate);
        }
      );
    }
  }

  onMultiSelectChange(selectedList, item, id) {
    if (id === "program") {
      this.setState({
        programsSelectedList: selectedList,
      });
    } else if (id === "campus") {
      this.setState({
        campusSelectedList: selectedList,
      });
    } else if (id === "invoiceNo") {
      this.setState({
        invoiceNoSelectedList: selectedList,
      });
    }
    this.setState(
      {
        isOpen: false,
        expanded: "",
        background: "hidden",
      },
      function () {
        this.loadData();
      }
    );
  }

  renderAdvancedSearch() {
    return (
      <div>
        <div className="md:mt-5 mt-1 flex xl:justify-end">
          <span
            onClick={() => this.filtersDropdown()}
            className="  p-2 h-7 rounded-md bg-white border border-gray-300 text-gray-700 hover:bg-gray-200 cursor-pointer"
          >
            Advanced Search
          </span>
        </div>
        <Transition
          show={this.state.isOpen}
          enter="transition ease-in duration-300 transform"
          enterFrom="opacity-25 scale-0"
          enterTo="opacity-100 scale-100"
          leave="transition ease-out duration-500 transform"
          leaveFrom="opacity-100 scale-100"
          leaveTo="opacity-25 scale-0"
        >
          <div className="origin-top right-0 absolute z-10 mb-8 w-full bg-gray-200">
            <div className="px-2 pt-4 lg:mt-0 flex-shrink-0 bg-gray-100 sm:rounded-lg border-b border-gray-200">
              <div className={"mx-4 mb-4 bg-gray-100"}>
                <div className="pb-4">
                  <div
                    className={" text-gray-600 w-full flex justify-center mb-4"}
                  >
                    <h1 className="font-semibold">Filters</h1>
                  </div>
                  <div className=" grid grid-cols-6 gap-6">
                    <div className="md:py-3 col-span-6 md:col-span-3 ">
                      <MultiSelectBox
                        id="program"
                        label="Select Programs"
                        selectedValues={this.state.programsSelectedList}
                        data={this.state.allPrograms}
                        onSelect={(selectedList, selectedItem) =>
                          this.onMultiSelectChange(
                            selectedList,
                            selectedItem,
                            "program"
                          )
                        }
                        onRemove={(selectedList, removedItem) =>
                          this.onMultiSelectChange(
                            selectedList,
                            removedItem,
                            "program"
                          )
                        }
                      />
                    </div>
                    <div className="md:py-3 col-span-6 md:col-span-3 ">
                      <MultiSelectBox
                        id="campus"
                        label="Select Campuses"
                        selectedValues={this.state.campusSelectedList}
                        onSelect={(selectedList, selectedItem) =>
                          this.onMultiSelectChange(
                            selectedList,
                            selectedItem,
                            "campus"
                          )
                        }
                        onRemove={(selectedList, removedItem) =>
                          this.onMultiSelectChange(
                            selectedList,
                            removedItem,
                            "campus"
                          )
                        }
                        data={this.state.allCampuses}
                        placeholder="Select"
                      />
                    </div>
                    <div className="md:py-3 col-span-6 md:col-span-3 ">
                      <MultiSelectBox
                        id="invoiceNo"
                        label="Select Invoice Number"
                        selectedValues={this.state.invoiceNoSelectedList}
                        onSelect={(selectedList, selectedItem) =>
                          this.onMultiSelectChange(
                            selectedList,
                            selectedItem,
                            "invoiceNo"
                          )
                        }
                        onRemove={(selectedList, removedItem) =>
                          this.onMultiSelectChange(
                            selectedList,
                            removedItem,
                            "invoiceNo"
                          )
                        }
                        data={this.state.allInvoiceNo}
                        placeholder="Select"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Transition>
        <div className="">
          <span
            onClick={() => this.filtersDropdown()}
            className={
              this.state.background +
              " z-6 fixed top-10 left-0 right-0 bottom-0 h-full w-full bg-gray-400 opacity-75"
            }
          />
        </div>
      </div>
    );
  }

  render() {
    return (
      <div className="relative mt-6 align-middle min-h-full">
        <span
          className={
            this.state.hidden +
            " fixed top-10 left-0 right-0 bottom-0 h-full w-full bg-white opacity-25"
          }
        />
        <Loader loaded={this.state.isLoaded}></Loader>

        <div className="flex rounded-md p-4 mb-4 md:mb-8 w-full grid grid-cols-1 gap-5 md:grid-cols-2 xl:grid-cols-6 bg-gray-100">
          <div className="xl:col-span-2">
            <SearchBox
              id={"search"}
              label={"Search Student Name"}
              svgType={"student"}
              placeholder={"Name"}
              value={this.state.value}
              searchHandler={() => {
                this.searchHandeler();
              }}
            />
          </div>

          <div className="xl:col-span-1">
            <DateBox
              id={"Pd1"}
              label="Invoice Date From"
              //minDate={moment().add(0, "days").toDate()}
              onChange={this.handleDateBox}
              name={"FromDate"}
              value={this.state.fromDate ? new Date(this.state.fromDate) : ""}
            />
          </div>
          <div className="xl:col-span-1">
            <DateBox
              id={"Pd1"}
              label="Invoice Date Till"
              //minDate={moment().add(0, "days").toDate()}
              onChange={this.handleDateBox}
              name={"ToDate"}
              value={this.state.toDate ? new Date(this.state.toDate) : ""}
              //onChange={this.handleChange}
            />
          </div>
          <div className="xl:col-span-2">{this.renderAdvancedSearch()}</div>
        </div>
        <div>{this.renderTable()}</div>
      </div>
    );
  }
}

export default CommissionDueView;
